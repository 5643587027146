import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { is } from 'ramda';

import SvgIcon from 'views/shared/SvgIcon';

const Button = forwardRef(({
  id,
  className,
  text,
  kind,
  isFullWidth,
  type,
  disabled,
  isLink,
  href,
  target,
  isLabel,
  htmlFor,
  onClick,
  icon,
  iconRight,
  size,
  isLoading,
  isDownload,
  active,
}, ref) => {
  const primaryButton = kind !== 'empty' && kind !== 'back' && kind !== 'link';
  const buttonClassNames = classNames(
    className,
    'main-btn',
    {
      'main-btn--primary': primaryButton,
      'main-btn--empty': kind === 'empty',
      'main-btn--outline': kind === 'outline',
      'main-btn--tertiary': kind === 'tertiary',
      'main-btn--secondary': kind === 'secondary',
      'main-btn--link': kind === 'link',
      'main-btn--flat': kind === 'flat',
      'main-btn--danger': kind === 'danger',
      'main-btn--full-width': isFullWidth,
      'main-btn--md': size === 'medium',
      'main-btn--sm': size === 'small',
      'main-btn--accent': kind === 'outline-danger',
      'main-btn--warning': kind === 'warning',
      'main-btn--back': kind === 'back',
      'main-btn--secondary-accent': kind === 'secondary-accent',
      'main-btn--loading': isLoading,
      'main-btn--active': active,
      'w-full': isFullWidth,
    },
  );

  if (isLink) {
    return (
      <a
        id={id}
        href={href}
        className={buttonClassNames}
        onClick={onClick}
        target={target}
        download={isDownload}
      >
        {icon && !iconRight && (
          <div className="main-btn__icon-wrap">
            <SvgIcon icon={icon} className="main-btn__icon" />
          </div>
        )}
        <span className="main-btn__txt">
          <FormattedMessage {...text} />
        </span>
        {icon && iconRight && (
          <div className="main-btn__icon-wrap">
            <SvgIcon icon={icon} className={classNames('main-btn__icon', iconRight && 'main-btn__icon--right')} />
          </div>
        )}
      </a>
    );
  }

  if (isLabel) {
    return (
      <label
        id={id}
        className={buttonClassNames}
        htmlFor={htmlFor}
      >
        <span className="main-btn__txt">
          <FormattedMessage {...text} />
        </span>
      </label>
    );
  }

  return (
    <button
      id={id}
      type={type}
      className={buttonClassNames}
      disabled={disabled}
      onClick={onClick}
      ref={ref}
    >
      {isLoading ? (
        <div className="main-btn__icon-wrap">
          <SvgIcon icon="spinner" />
        </div>
      )
      : (
        <>
          {icon && !iconRight && (
            <div className="main-btn__icon-wrap">
              <SvgIcon icon={icon} className="main-btn__icon" />
            </div>
          )}
          <span className="main-btn__txt">
            {is(Object, text) ? <FormattedMessage {...text} /> : text}
          </span>
          {icon && iconRight && (
            <div className="main-btn__icon-wrap">
              <SvgIcon icon={icon} className={classNames('main-btn__icon', iconRight && 'main-btn__icon--right')} />
            </div>
          )}
        </>
      )}
    </button>
  );
});

Button.defaultProps = {
  className: null,
  kind: null,
  isFullWidth: false,
  type: 'button',
  target: null,
  disabled: false,
  isLink: false,
  href: null,
  isLabel: false,
  htmlFor: null,
  onClick: undefined,
  icon: null,
  iconRight: null,
  size: null,
  id: undefined,
  isLoading: false,
  isDownload: false,
  active: undefined,
};

Button.propTypes = {
  id: PropTypes.string,
  isDownload: PropTypes.bool,
  className: PropTypes.string,
  target: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape(),
  ]).isRequired,
  kind: PropTypes.oneOf(['primary', 'flat', 'outline', 'danger', 'outline-danger', 'warning', 'secondary', 'tertiary', 'link', 'back', 'empty', 'secondary-accent', null]),
  icon: PropTypes.oneOf(['settings', 'close', 'cross', 'alert', 'info', 'tick', 'plus', 'filter', 'edit-pencil', 'video', 'arrow-down', 'copy', 'live-preview', 'template', 'shared', 'checked', 'download', 'arrow-left', 'left-long', 'list-checked', null]),
  iconRight: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', null]),
  isFullWidth: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  disabled: PropTypes.bool,
  isLink: PropTypes.bool,
  href: PropTypes.string,
  isLabel: PropTypes.bool,
  htmlFor: PropTypes.string,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  active: PropTypes.bool,
};

Button.displayName = 'Button';

export default Button;
